<script setup lang="ts">
import { getProductOptionsNames, getSelectedProductVariant } from '~/utils/productGetters'
import type { Product } from '~/types/product'

const props = defineProps({
  product: {
    type: Object as PropType<Product>,
    default: () => ({}),
  },
  productConfiguration: {
    type: Object,
    default: () => ({}),
  },
  isAtcModal: {
    type: Boolean,
    default: false,
  },
})

const { setSoldOutFormData } = useUiState()
const {
  public: { showAlmostOutOfStock },
} = useRuntimeConfig()
const errorNoVariantSelected = useState('errorNoVariantSelected', () => false)
const productNames = getProductOptionsNames(props.product)
const selectedProduct = computed(() => getSelectedProductVariant(props.product, props.productConfiguration))

const { isMobile } = useUiState()
const { tests } = storeToRefs(useAbTestStore())
const ab25001 = ref()
const ab25001showStockWarningMessage = computed(
  () =>
    (selectedProduct.value.stock_indication === 1 && ab25001.value !== 'v1') ||
    (selectedProduct.value.stock_indication === 1 && !isMobile),
)

onMounted(() => {
  if (props.product?.stock_status === 'OUT_OF_STOCK') {
    setSoldOutFormData({
      productId: props.product?.pid,
      variantId: selectedProduct.value?.id,
      type: 'soldOut',
    })
  }

  ab25001.value = tests.value.get(25001)
})

onUnmounted(() => {
  if (props.product?.stock_status === 'OUT_OF_STOCK')
    setSoldOutFormData({ productId: null, variantId: null, optionLabel: null, type: null, isModalVisible: false })
})
</script>

<template>
  <div v-if="product" class="product__actions">
    <slot>
      <SfNotification
        :visible="errorNoVariantSelected"
        :message="
          $t('Please select {names}', {
            names: productNames,
          })
        "
        :persistent="false"
      />
      <ProductStockWarningMessage v-if="ab25001showStockWarningMessage && showAlmostOutOfStock" />
      <template v-if="!product?.variants && selectedProduct.stock_status === 'OUT_OF_STOCK'">
        <ProductSoldOutForm>
          <ProductAddToWishList
            class="form-button product__add-to-wishlist sf-button--outline product__add-to-wishlist-button"
            type="button"
            :product="product"
          />
        </ProductSoldOutForm>
      </template>

      <div v-else class="product__add-to-cart" :class="{ 'product__add-to-cart--modal': isAtcModal }">
        <ProductAddToCartButton :product :product-configuration />
        <ProductBrands v-if="!isAtcModal" />
      </div>
    </slot>

    <slot name="additional-actions">
      <div v-if="product.stock_status !== 'OUT_OF_STOCK'" class="product__additional-actions">
        <ProductAddToWishList
          v-if="!isAtcModal"
          class="form-button product__add-to-wishlist sf-button--outline product__add-to-wishlist-button"
          :product="product"
          :selectedVariant="selectedProduct"
        />
      </div>
    </slot>
  </div>
</template>

<style lang="scss">
html.theme--storefront {
  .product {
    &__actions {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      margin-bottom: var(--spacer-sm);

      @include for-mobile {
        padding: 0 var(--spacer-15);
      }
    }

    &__add-to-cart {
      flex-flow: column;
      margin: 0;
      flex-grow: 1;

      .sf-add-to-cart__button {
        width: 100%;
      }
    }

    &__add-to-wishlist {
      &.form-button {
        min-width: 46px;
        margin-left: var(--spacer-xs);

        @include for-desktop {
          min-width: 54px;
        }
      }
    }

    &__additional-actions {
      align-self: self-start;
      display: flex;
      justify-content: flex-start;
      margin: 0 0 0 var(--spacer-xs);
    }

    .sf-notification {
      width: 100%;
      padding: 8px 8px 8px 16px;
      margin: 0 0 var(--spacer-sm);
      background-color: var(--red-background-color);
      color: var(--red-background-color) !important;

      .sf-icon,
      .sf-button {
        display: none;
      }

      &__message {
        color: black;
      }
    }
  }

  .product__add-to-cart--modal {
    width: 100%;

    .sf-button {
      --button-background: var(--cta-pink-color);
      --button-color: var(--white-color);

      &.is-disabled--button {
        --button-color: var(--white-color);
        --button-background: var(--primary-color);
      }

      &:active {
        --button-background: var(--primary-color);
        --button-color: var(--white-color);
      }
    }
  }
}
</style>
