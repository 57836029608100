<script setup lang="ts">
import getIsDisabled from '~/utils/getIsDisabledProductVariantOption'

const props = defineProps({
  option: {
    type: Object,
    required: true,
  },
  optionsLength: {
    type: Number,
    required: true,
  },
  parentUid: {
    type: String,
    required: true,
  },
  productConfiguration: {
    type: Object,
    required: true,
  },
  type: {
    type: String as PropType<'wishlist' | 'cart'>,
    required: true,
  },
  sku: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['updateProductConfiguration'])
const { products } = storeToRefs(useProductStore())

const { setSoldOutFormData } = useUiState()
const { t } = useI18n()
const errorNoVariantSelected = ref(false)

const translatedLabels = {
  size: t('Size'),
  base_color: t('Color'),
  birth_year: t('Birth year'),
  birth_month: t('Birth month'),
  zodiac_sign: t('Zodiac sign'),
  initial: t('Initial'),
  phone_type: t('Phone model'),
}

const translatedLabel = computed(() => translatedLabels[props.option.attribute_code])

const selectedVariant = computed(
  () => props.option.values.find((value) => value.uid === props.productConfiguration[props.option.attribute_uid]) || {},
)

const selectVariant = (uid) => {
  const value = props.option.values.find((value) => value?.uid === uid)
  emit('updateProductConfiguration', { key: props.option.attribute_uid, value: value?.uid })
}

const showOutOfStockModal = (uid) => {
  const value = props.option.values.find((value) => value?.uid === uid)
  const product = products.value[props.sku]
  const configuration = { ...props.productConfiguration, [props.option.attribute_uid]: uid }
  const variantId = product.variants?.find((variant) =>
    variant?.attributes?.every((attr) => Object.values(configuration).includes(attr?.uid)),
  )?.product?.id

  if (value?.out_of_stock === 'OUT_OF_STOCK' && variantId) {
    return setSoldOutFormData({
      isModalVisible: true,
      optionLabel: value.label,
      productId: product.pid,
      variantId,
      type: 'soldOut',
    })
  }
}

const disabled = computed(() => getIsDisabled(props))

const showErrorNotification = () => {
  if (disabled.value) {
    errorNoVariantSelected.value = true
    return setTimeout(() => (errorNoVariantSelected.value = false), 3000)
  }
}

const options = computed(() =>
  props.option.values.map((value) => ({
    label: value.label,
    value: value.uid,
    disabled: value.out_of_stock === 'OUT_OF_STOCK' && props.type !== 'wishlist',
    lowStock: value.stock_indication === 1,
  })),
)

watch(
  () => props.productConfiguration[props.parentUid],
  //reset selected variant whenever the parent is changed
  (prev, next) => {
    if (prev !== next) props.productConfiguration[props.option.attribute_uid] = null
  },
)

const errorNotificationMessage = ref(
  props.optionsLength > 2 ? 'Please select the option above first' : 'Please select a color first',
)
</script>

<template>
  <div v-if="option" class="product-variant product-variant--select" data-testid="product-variant-select">
    <div class="product-variant__heading">
      <SfProperty :name="translatedLabel" :value="selectedVariant.label" />
    </div>
    <SfSelect
      :options="options"
      :value="selectedVariant.uid"
      :placeholder="`${$t('Choose an option')}...`"
      :disabled="disabled"
      :data-label="$t('Choose an option')"
      :data-attribute_code="option.attribute_code"
      data-testid="product-variant-select-muliselect"
      @change="selectVariant"
      @click="showErrorNotification"
      @selectOutOfStockItem="showOutOfStockModal"
    />
    <SfNotification
      class="no-variant-error"
      :visible="errorNoVariantSelected"
      :message="$t(errorNotificationMessage)"
      :persistent="false"
    />
  </div>
</template>

<style lang="scss">
html.theme--storefront {
  .sf-component-select {
    &__label,
    svg {
      pointer-events: none;
    }
  }

  .sf-component-select-option--out-of-stock {
    color: var(--gray-secondary-color);

    svg {
      color: #000;
      margin-left: auto;
    }
  }

  .sf-component-select.sf-component-select--outline .sf-component-select__dropdown {
    position: absolute;
    width: 100%;
  }

  .sf-component-select.is-disabled .sf-component-select-option {
    border: 1px solid var(--gray-background-color);
    cursor: default;
  }

  .no-variant-error {
    margin-top: var(--spacer-12) !important;
    margin-bottom: -6px !important;
  }

  .product-variant--select .sf-notification {
    padding: 8px 8px 8px 16px;
    margin: 0 0 var(--spacer-sm);
    background-color: var(--red-background-color);
    color: var(--red-background-color) !important;

    .sf-icon,
    .sf-button {
      display: none;
    }

    &__message {
      color: var(--black-color);
    }
  }
}
</style>
