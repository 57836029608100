<script setup lang="ts">
import BellIcon from '~/storefront/assets/icons/bell.svg'
import { getSelectedProductVariantByUid } from '~/utils/productGetters'

const props = defineProps({
  option: {
    type: Object,
    required: true,
  },
  productConfiguration: {
    type: Object,
    required: true,
  },
  type: {
    type: String as PropType<'wishlist' | 'cart'>,
    required: true,
  },
  sku: {
    type: String,
    required: true,
  },
  selectedVariant: {
    type: Object,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: true,
  },
})
const { useAb25001, useAb25022 } = useAbTest()
const { showV0 } = useAb25001()
const { ab25022showV0 } = useAb25022()
const abTestStore = useAbTestStore()
const { sendAbTestEvent, sendAbShowEvent } = abTestStore
const config = useRuntimeConfig()

const emit = defineEmits(['selectVariant'])

const { toggleIsSizeChartSidebarOpen } = useUiState()

const getDisabledForOption = (out_of_stock_status) =>
  out_of_stock_status === 'OUT_OF_STOCK' && props.type !== 'wishlist'

const selectedProductSku = (uid) =>
  getSelectedProductVariantByUid(props.sku, props.productConfiguration, props.option.attribute_uid, uid)?.sku
const selectedProductId = (uid) =>
  getSelectedProductVariantByUid(props.sku, props.productConfiguration, props.option.attribute_uid, uid)?.id

const showProductStockWarningIndicator = (size) => {
  return props.type === 'cart' && size.stock_indication === 1 && config.public.showAlmostOutOfStock
}

const sendAbEvents = (size) => {
  emit('selectVariant', size)
  if (showV0.value) {
    sendAbTestEvent(25001, 'click size selected')
  }
  if (ab25022showV0.value) {
    sendAbTestEvent(25022, 'click size selected')
  }
}

const sizeDesktop = ref()

onMounted(() => {
  if (showV0.value) {
    sendAbShowEvent(25001, sizeDesktop)
  }
  if (ab25022showV0.value) {
    sendAbShowEvent(25022, sizeDesktop)
  }
})
</script>

<template>
  <div ref="sizeDesktop" class="product-variant__heading">
    <SfProperty :name="$t('Size')" :value="selectedVariant.label" />

    <div class="product-variant__size-chart-button">
      (<SfButton class="sf-button--text" @click="toggleIsSizeChartSidebarOpen"> {{ $t('Size chart') }} </SfButton>)
    </div>
  </div>
  <div class="product-variant__product-sizes--desktop" data-testid="product-variant-size-desktop">
    <SfButton
      v-for="size in option.values"
      :key="size.uid"
      class="product-size"
      :class="{
        'product-size--selected': selectedVariant.uid === size.uid,
        'product-size--out-of-stock': getDisabledForOption(size.out_of_stock) || disabled,
      }"
      :data-sku="selectedProductSku(size.uid)"
      :data-id="selectedProductId(size.uid)"
      :data-label="size.label"
      :data-attribute_code="option.attribute_code"
      @click="sendAbEvents(size)"
    >
      {{ size.label }}
      <ProductStockWarningIndicator
        v-if="showProductStockWarningIndicator(size)"
        class="product-size__stock-indication"
      />
      <BellIcon v-if="getDisabledForOption(size.out_of_stock)" />
    </SfButton>
  </div>
</template>

<style lang="scss" scoped>
.product-variant__product-sizes--desktop {
  display: flex;
  flex-flow: row wrap;

  .is-disabled--button {
    pointer-events: all;
  }
}

.product-size {
  --button-height: var(--variant-button-height);
  --button-background: white;
  --button-color: var(--black-color);
  --button-padding: 0 var(--spacer-sm);
  --button-font-size: var(--font-size--xs);
  --button-font-weight: var(--font-weight--normal);
  border: 1px solid #c3c3c3;
  margin: 0 var(--spacer-xs) 0 0;
  width: 50px;

  &:hover,
  &:focus,
  &--selected {
    --button-background: white;
    --button-color: var(--black-color);
    border-color: var(--black-secondary-color);
    font-weight: var(--font-weight-bold);
  }

  &__stock-indication {
    position: absolute;
    right: -6px;
    top: -6px;
    width: 12px;
    height: 12px;
    border: 2px solid white;
  }

  &--out-of-stock {
    border: 1px solid var(--gray-background-color);
    --button-background: var(--white-color);
    --button-color: var(--gray-secondary-color);
    --button-box-shadow: none;
    --button-hover-box-shadow: none;

    &:hover,
    &:focus {
      border: 1px solid #c3c3c3;
      --button-background: var(--white-color);
      --button-color: var(--gray-secondary-color);
      --button-box-shadow: none;
      --button-hover-box-shadow: none;
    }

    svg {
      position: absolute;
      top: -3px;
      right: -4px;
      color: var(--black-color);
    }
  }
}

.product-variant__size-chart-button,
.product-variant__size-chart-button button {
  font-size: var(--font-size--xs);
}
</style>
