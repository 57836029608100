<script setup lang="ts">
import VisaLogo from '~/storefront/assets/logos/visa.svg'
import Klarna from '~/storefront/assets/logos/klarna.svg'
import PaypalLogo from '~/storefront/assets/logos/paypal.svg'

const { locale } = useI18n()

const logos = [
  { logo: VisaLogo, width: 44, height: 14 },
  { logo: Klarna, width: 48, height: 24 },
  { logo: PaypalLogo, width: 56, height: 15 },
]
</script>

<template>
  <div v-if="locale === 'de'" class="product-brands">
    <div v-for="({ logo, width, height }, i) in logos" :key="i" class="product-brands__logo">
      <component :is="logo" :width="width" :height="height" />
    </div>
  </div>
</template>

<style lang="scss">
html.theme--storefront {
  .product-brands {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    min-height: var(--spacer-xl);
    margin: var(--spacer-sm) 0;

    &__logo {
      opacity: 0.42;
      filter: grayscale(1);

      svg {
        display: block;
      }
    }
  }
}
</style>
