<script setup lang="ts">
import BellIcon from '~/storefront/assets/icons/bell.svg'
import { getSelectedProductVariantByUid } from '~/utils/productGetters'

const props = defineProps({
  option: {
    type: Object,
    required: true,
  },
  productConfiguration: {
    type: Object,
    required: true,
  },
  type: {
    type: String as PropType<'wishlist' | 'cart'>,
    required: true,
  },
  sku: {
    type: String,
    required: true,
  },
  selectedVariant: {
    type: Object,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: true,
  },
})

const { sendAbTestEvent, sendAbShowEvent } = useAbTestStore()
const sizeMobile = ref()
const config = useRuntimeConfig()

const emit = defineEmits(['selectVariant', 'showError'])

const drawerOpen = ref(false)

const { toggleIsSizeChartSidebarOpen } = useUiState()
const { isSizeChartSidebarOpen, soldOutForm } = storeToRefs(useUiState())

const getDisabledForOption = (out_of_stock_status) =>
  out_of_stock_status === 'OUT_OF_STOCK' && props.type !== 'wishlist'

onMounted(() => sendAbShowEvent(25001, sizeMobile))

const selectedProductSku = (uid) =>
  getSelectedProductVariantByUid(props.sku, props.productConfiguration, props.option.attribute_uid, uid)?.sku
const selectedProductId = (uid) =>
  getSelectedProductVariantByUid(props.sku, props.productConfiguration, props.option.attribute_uid, uid)?.id

const showProductStockWarningIndicator = props.type === 'cart' && config.public.showAlmostOutOfStock

const openSizeChart = async () => {
  toggleIsSizeChartSidebarOpen()
  drawerOpen.value = false

  watch(
    isSizeChartSidebarOpen,
    () => {
      drawerOpen.value = true
    },
    { once: true },
  )
}

const selectOption = (size: Object) => {
  sendAbTestEvent(25001, 'click size selected')
  emit('selectVariant', size)
  drawerOpen.value = false

  watch(
    soldOutForm,
    () => {
      drawerOpen.value = true
    },
    { once: true },
  )
}

const clickSizeSelector = () => {
  if (props.disabled) {
    emit('showError')
  } else {
    sendAbTestEvent(25001, 'click size selection open')
  }
}
</script>

<template>
  <div ref="sizeMobile" class="product-variant__product-sizes--mobile" data-testid="product-variant-size-mobile">
    <UDrawer
      v-model:open="drawerOpen"
      class="rounded-l"
      title="Kies je maat"
      :ui="{ content: 'rounded-t-2xl', title: 'font-medium' }"
    >
      <UButton
        :class="{
          'product-size--disabled': disabled,
          '[&>span[class*=chevron-down]]:rotate-180 [&>span[class*=chevron-down]]:transform [&>span[class*=chevron-down]]:transition':
            drawerOpen,
        }"
        :disabled="disabled"
        color="secondary"
        variant="outline"
        trailing-icon="i-ph-caret-up"
      >
        <div
          class="flex justify-between w-100"
          :class="{ 'font-medium': selectedVariant.uid, 'font-normal': !selectedVariant.uid }"
          @click="clickSizeSelector"
        >
          {{ selectedVariant.uid ? selectedVariant?.label : $t('Select your size') }}
          <span
            v-if="selectedVariant.stock_indication === 1 && showProductStockWarningIndicator"
            class="flex items-center"
          >
            <ProductStockWarningIndicator class="mr-1.5 h-[6px] w-[6px]" />
            <p class="text-sm">Bijna uitverkocht</p>
          </span>
        </div>
        <template #trailing>
          <span class="chevron-down ml-3">
            <svg width="15" height="7.5" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.5 0.500031L8 8.00003L0.5 0.500031"
                stroke="#6A6A6A"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </template>
      </UButton>

      <template #body>
        <div class="bg-[#F6F5F5] -mx-4 px-4 py-1">
          <UButton
            class="underline text-base justify-start -ml-2 font-normal focus-visible:ring-[#F6F5F5]"
            variant="link"
            color="secondary"
            size="xs"
            @click="openSizeChart"
          >
            {{ $t('Size chart') }}
          </UButton>
        </div>
        <UButton
          v-for="size in option.values"
          :key="size.uid"
          class="border-b-1 rounded-none border-neutral-200 relative text-base justify-start font-medium"
          :class="{
            'product-size--selected': selectedVariant.uid === size.uid,
          }"
          variant="ghost"
          color="secondary"
          :data-sku="selectedProductSku(size.uid)"
          :data-id="selectedProductId(size.uid)"
          :data-label="size.label"
          :data-attribute_code="option.attribute_code"
          @click="selectOption(size)"
        >
          <span
            :class="{
              'product-size--out-of-stock': getDisabledForOption(size.out_of_stock),
            }"
          >
            {{ size.label }}
          </span>
          <template v-if="size.stock_indication === 1 && showProductStockWarningIndicator">
            <ProductStockWarningIndicator class="ml-auto h-[6px] w-[6px]" />
            <p class="text-sm font-normal">Bijna uitverkocht</p>
          </template>
          <template v-if="getDisabledForOption(size.out_of_stock)">
            <BellIcon class="ml-auto" />
            <p class="text-sm font-normal">Houd me op de hoogte</p>
          </template>
        </UButton>
      </template>
    </UDrawer>
  </div>
</template>

<style lang="scss" scoped>
.product-size--out-of-stock {
  color: #c3c3c3;
}

.is-disabled--button {
  pointer-events: all;
}

.product-size--disabled {
  background-color: #f3f4f6;
  color: 'red';
}
</style>
